<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import {getStuats} from "@/utils/api"
export default {
  data() {
    return {
      timer: null,
    }
  },
  created() {
    this.timer = setInterval(() => {
      this.checkLoginStatus();
    }, 15000);
    //除登录页外其他页面均禁止复制粘贴
    //禁止复制粘贴
    window.addEventListener('copy', (e) => {
        if(this.$route.path!=='/login') {
          e.preventDefault()
        }else {}
    })
    //禁止粘贴
    window.addEventListener('paste', (e) => {
      if(this.$route.path!=='/login') {
        e.preventDefault()
      }else {}
    })
  },
  methods: {
    checkLoginStatus() {
      var token = localStorage.getItem('ctoken')
      if (token) {
        getStuats().then(response => {
          console.log('在线')
        }).catch(error => {
          this.$router.push('/login')
        })
      } else {
        console.log('在执行')
      }
    }
  },
  beforeDestroy(){
    //清除页面监听
    window.removeEventListener('paste')
    window.removeEventListener('copy')
  },
  destroyed() {
    clearInterval(this.timer)
  }
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}


::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 5px;
}
::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #666;
}
::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #eee;
}
</style>
